import axios from "axios";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: "https://backend.aligners.app/",
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

// ℹ️ Add request interceptor to send the authorization header on each subsequent request after login
axiosIns.interceptors.request.use((config) => {
 
    // Get request headers and if headers is undefined assign blank object
    config.headers = config.headers || {};
    config.headers['Authorization-Key'] = 'c2ac528d2A315a839L4da441c29153b9294fdfb5Alignerz'
    // Set authorization header
  

  // Return modified config
  return config;
});

// ℹ️ Add response interceptor to handle 401 response
axiosIns.interceptors.response.use(
  (response) => {
    console.log(response);
    return response;
  },
  (error) => {
    if (error.response) {
      // Handle custom error responses
      const { status, data } = error.response;
      if (status === 403) {
        return Promise.reject(new Error(data.message || 'Request failed'));
      }
    }
    return Promise.reject(error);
  }
);
export default axiosIns;
