<template>
  <div class="drawer-app">
    <div class="d-flex align-center justify-space-between">
      <router-link to="/" class="d-flex align-center">
        <img
          class="cursor-pointer"
          width="60"
          :src="
            require(`@/assets/images/global/logo-icon${
              isDark ? '-dark' : ''
            }.svg`)
          "
        />
      </router-link>

      <div class="d-flex align-center">
        <img
          class="theme-icon cursor-pointer mx-5"
          :src="require(`@/assets/images/icons/${isDark ? 'moon' : 'sun'}.svg`)"
          @click="changeStyleHandler"
        />
        <v-menu v-model="menu" location="end">
          <template v-slot:activator="{ props }">
            <img
              v-bind="props"
              class="theme-icon cursor-pointer"
              :src="
                require(`@/assets/images/icons/menu${
                  menu ? '-filled' : ''
                }.svg`)
              "
            />
          </template>

          <div
            class="px-10 py-8 text-center drawer-content"
            :class="isDark ? 'drawer-content-dark' : ''"
          >
            <router-link to="/bidding-results" class="d-block mb-7">
              <span
                class="font-20 font-500"
                :class="isDark ? 'text-white' : 'text-black'"
                >Biding results</span
              >
            </router-link>
      
            <router-link to="/claiming-portal" class="d-block mb-7">
              <span
                class="font-20 font-500"
                :class="isDark ? 'text-white' : 'text-black'"
                >Claiming Portal</span
              >
            </router-link>


            <div class="radius-30 px-5 py-3 bg-primary cursor-pointer relative">
              <span class="text-white font-20 font-500">Go to App</span>
            </div>
          </div>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import { useTheme } from "vuetify";

export default {
  name: "drawer-app",
  setup() {
    let store = useStore();
    const theme = useTheme();

    return { store, theme };
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    ...mapGetters(["isDark"]),
  },
  methods: {
    changeStyleHandler() {
      this.store.dispatch("changeStyleMood");
      if (this.isDark) this.theme.global.name.value = "darkTheme";
      if (!this.isDark) this.theme.global.name.value = "lightTheme";
    },
  },
};
</script>

<style lang="scss">
.drawer-app {
  padding: 16px 32px;

  @media (max-width: 600px) {
    padding: 12px 20px;
  }
}
.drawer-content {
  box-shadow: 0px 0px 20px 1px #0924a94d !important;
  border-radius: 10px;
  background: white;
  z-index: 9;
}
</style>
