
// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";


const lightTheme = {
  dark: false,
  colors: {
    primary: "#0924A9",
    'outline-primary': '#DEE8FF',
    black: '#1B1B1F',
    black2: '#1B1B1F',
    whitebg: '#F5F5F5',
    error: '#D32828',
    yellow: '#EDC346',
    bgColor: '#f5f5f5',
    pink: '#A9097C',
    darkblack: '#000000'

  },
}


const darkTheme = {
  dark: true,
  colors: {
    primary: "#0055FF",
    'outline-primary': '#DEE8FF',
    black: '#1B1B1F',
    whiteText: '#f5f5f5',
    black2: '#F5F5F5',
    whitebg: '#F5F5F5',
    error: '#D32828',
    yellow: '#EDC346',
    bgColor: '#f5f5f5',
    pink: '#A9097C',
    darkblack: '#000000'


  },
}


export default createVuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  breakpoint: {
    scrollBarWidth: 24,
  },
  components,
  directives,
  theme: {
    defaultTheme: 'lightTheme',
    themes: {
      lightTheme,
      darkTheme,
    },
  },
});
