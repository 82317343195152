<template>
  <div
    class="d-flex align-center justify-space-between pool-line"
    :class="
      item.isFirst ? 'pool-line--first bg-primary' : 'pool bg-outline-primary'
    "
  >
    <span
      class="font-20"
      :class="
        isDark ? 'text-white' : item.isFirst ? 'text-white' : 'text-primary'
      "
    >
      {{ item.idx + 1 }}<sup>st</sup> pool
    </span>
    <span
      class="font-16"
      :class="
        isDark ? 'text-white' : item.isFirst ? 'text-white' : 'text-primary'
      "
    >
      {{ convertNumber(item.roundAmounts) }} IWO
    </span>
    <span
      class="font-16"
      :class="
        isDark ? 'text-white' : item.isFirst ? 'text-white' : 'text-primary'
      "
    >
      1 IWO = {{item.iwoPrice  / 1000000}} USDT
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters(["isDark"]),
  },
  methods: {
    convertNumber(value) {
      let n = new Intl.NumberFormat().format(value);
      return n;
    }
  },
};
</script>

<style lang="scss">
.pool-line {
  padding: 8px 30px;
  border-radius: 10px;

  &--first {
    span {
      filter: drop-shadow(0px 3px 9px #ffffffc7);
    }
  }

  @media (max-width: 960px) {
    padding: 6px 18px;

    span {
      font-size: 13px;
    }
  }
  @media (max-width: 600px) {
    padding: 6px 14px;

    span {
      font-size: 11px;
    }
  }
}
</style>
