import Service from "@/services/Service";
// import { useNotifyStore } from "@/stores/NotifyStore";
export default class Projectervice {
  resource = '';
//   notificationStore = useNotifyStore();
  constructor(resource) {
    this.resource = resource;
  }
  service = Service;
  async getList(
    page,
    searchFilter = {},
    url = this.resource,
    type = "get"
  ) {
    const requestParams = Object.assign(
      {},
      {
        page,
      }
    );
    const { data } = await this.service({
      method: type,
      url: url,
      data: searchFilter,
      params: requestParams,
    });

    console.log(data);
    return {
        data
    };
  }

  async addToWhiteList(address) {
    const { data } = await this.service({
      method: 'post',
      url: 'project/add-to-whitelist',
      data: {
        'address': address
      }
    });

    console.log(data);
    return {
        data
    };
  }


  async getClaimDetails(address) {
    const requestParams = Object.assign(
      {address},

    );
    const  {data}  = await this.service({
      method: 'get',
      url: 'project/claim-details',
      params: requestParams,
    });

    return {
        data
    };

    
  }


  async getNftToken(address) {
    const requestParams = Object.assign(
      {address},

    );
    const  {data}  = await this.service({
      method: 'get',
      url: 'project/nft-token',
      params: requestParams,
    });

    return {
        data
    };

    
  }

  async broadcastBid() {
   
    const  {data}  = await this.service({
      method: 'post',
      url: 'project/broadcast_bid',
    });

    return {
        data
    };
  }
}
