<template>
  <div class="d-flex align-center">
    <div
      class="radius-30 px-5 py-3 bg-primary mx-3 cursor-pointer relative"
      v-if="isHomepage"
      @click="$router.push('/projects')"
    >
      <span class="text-white font-20 font-500">Go to App</span>
    </div>

    <div class="d-flex align-center" v-else>
      <!-- CONNECTED  -->
      <div
      v-if="hashedPublicAddress"
        class="radius-30 px-5 py-3 bg-primary mx-3 cursor-pointer relative"
        @click="openHandler('pay-kyc')"
      >
        <span class="text-white font-20 font-500">KYC your wallet</span>
      </div>
      <div class="d-flex align-center" v-if="hashedPublicAddress">
        <!-- CONNECTED WITHOUT KYC -->

        <!-- CONNECTED WITH KYC -->
        <div
          class="radius-30 px-5 py-3 bg-yellow mx-3 cursor-pointer relative"
          v-if="connectWithKYC && processingKYC"
          @click="completeKycHandler"
        >
          <span class="text-primary font-20 font-500">Processing KYC</span>
        </div>

        <!-- CONNECTED USER NUMBER -->
        <div
          class="radius-30 px-5 py-3 bg-outline-primary mx-3 cursor-pointer relative"
        >
          <span class="text-primary font-20 font-500">{{hashedPublicAddress}}</span>
        </div>
      </div>

      <!-- NOT CONNECTED  -->
      <div
        class="radius-30 px-5 py-3 bg-primary mx-3 cursor-pointer relative"
        v-else
        @click="openHandler('connect-wallet')"
      >
        <span class="text-white font-20 font-500">Connect wallet</span>
      </div>
    </div>

    <v-dialog v-model="dialog" :max-width="dialogType == 'connect-wallet'? '450': '600'">
      <connect-wallet
        v-if="dialogType == 'connect-wallet'"
        @close="closeHandler"
      ></connect-wallet>
      <pay-kyc v-if="dialogType == 'pay-kyc'" @close="closeHandler"></pay-kyc>
      <kyc-completed v-if="dialogType == 'complete-kyc'" @close="closeHandler"></kyc-completed>
    </v-dialog>
  </div>
</template>

<script>
import ConnectWallet from "@/components/dialogs/ConnectWallet.vue";
import PayKyc from "@/components/dialogs/PayKyc.vue";
import KycCompleted from '@/components/dialogs/KycCompleted.vue';
import { mapGetters, useStore } from "vuex";

export default {
  components: {
    ConnectWallet,
    PayKyc,
    KycCompleted,
  },
  setup() {
    let store = useStore();

    return { store };
  },
  data: () => ({
    dialog: false,
    dialogType: "",
  }),
  computed: {
    ...mapGetters(["isUserConnected", "connectWithKYC", "processingKYC", "hashedPublicAddress"]),
    isHomepage() {
      if (this.$route.name == "homepage") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openHandler(type) {
      this.dialog = true;
      this.dialogType = type;
    },
    closeHandler() {
      this.dialog = false;
      this.dialogType = "";
    },
    completeKycHandler(){
      this.store.dispatch("completeKYCHandler", true);
      this.dialog = true;
      this.dialogType = "complete-kyc";
    },

  },

};
</script>

<style></style>
