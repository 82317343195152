<template>
  <v-app class="app" :class="isDark ? 'dark-app' : 'light-app'">
    <navbar-app v-if="$vuetify.display.mdAndUp"></navbar-app>
    <drawer-app v-if="$vuetify.display.smAndDown"></drawer-app>
    <router-view></router-view>
    <footer-app></footer-app>
  </v-app>
</template>

<script>
import NavbarApp from "@/layout/Navbar.vue";
import DrawerApp from "@/layout/Drawer.vue";
import FooterApp from "@/layout/Footer.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    NavbarApp,
    DrawerApp,
    FooterApp,
  },

  computed: {
    ...mapGetters(["isDark"]),
  },
};
</script>

<style lang="scss">
.app{
  transition: 0.3s;
  overflow: hidden;
  
  @media (max-width: 1600px) {
    zoom: 90%;
  }
  @media (max-width: 1500px) {
    zoom: 85%;
  }
  @media (max-width: 1400px) {
    zoom: 80%;
  }
  @media (max-width: 1300px) {
    zoom: 75%;
  }
  @media (max-width: 1200px) {
    zoom: 70%;
  }
  @media (max-width: 960px) {
    zoom: 100%;
  }
}
</style>
