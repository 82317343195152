import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "homepage",
    component: () => import('../views/homepage/Index.vue'),
  },
  {
    path: "/claiming-portal",
    name: "claiming-portal",
    component: () => import('@/views/claiming-portal/Index.vue'),
  },
  {
    path: "/bidding-results",
    name: "bidding-results",
    component: () => import('@/views/bidding-results/Index.vue'),
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import('@/views/projects/Index.vue'),
  },
  {
    path: "/place-bid",
    name: "place-bid",
    component: () => import('@/views/place-bid/Index.vue'),
  },

  // {
  //   path: "/:catchAll(.*)",
  //   component: NotFound
  // },
  // Add more routes as needed
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})