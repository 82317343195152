<template>
  <div class="custom-dialog radius-10">
    <span class="d-block font-24 text-primary font-600">Connect Wallet</span>
    <img @click="$emit('close')" class="custom-dialog__close cursor-pointer"
      :src="require('@/assets/images/icons/close-icon.svg')" />

    <div class="text-center">
      <v-progress-circular v-if="loading" color="primary" indeterminate>
      </v-progress-circular>

      <div v-if="!loading">
        <div
          class="mt-6 px-6 py-3 radius-10 d-flex align-center justify-space-between bg-outline-primary cursor-pointer"
          @click="connectMetaMaskWalletHandler">
          <span class="font-600">Metamask</span>
          <img class="object-contain" width="35" height="30" :src="require('@/assets/images/global/metamask.svg')"
            alt="" />
        </div>
        <div
          class="mt-4 px-6 py-3 radius-10 d-flex align-center justify-space-between bg-outline-primary cursor-pointer"
          @click="connectWalletConnect">
          <span class="font-600">WalletConnect</span>
          <img class="object-contain" width="35" height="30" :src="require('@/assets/images/global/wallet-connect.svg')"
            alt="" />
        </div>

        <p class="font-12 mt-6">
          By connecting your wallet, you agree to our
          <span class="text-primary cursor-pointer"> Terms of Service</span> and
          our
          <span class="text-primary cursor-pointer"> Privacy Policy.</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import ProjectService from '@/services/ProjectService'; // Ensure this path is correct
// import { WalletConnectModal } from '@walletconnect/modal'

export default {
  setup(props, { emit }) {
    const store = useStore();
    const address = ref(null);
    const loading = ref(false);
    const service = new ProjectService('');

    const connectUserBy = () => {
      store.dispatch("connectUserHandler", true);
      loading.value = false; // Update loading state
      emit("close");
    };

    const connectMetaMaskWalletHandler = async () => {
      loading.value = true; // Set loading to true when starting
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length > 0) {
          address.value = accounts[0];

          store.dispatch("setPublicAddress", address.value);
          store.dispatch("setPublicAddressHashed", address.value);
          let addToWhiteListService = await service.addToWhiteList(address.value);
          if (addToWhiteListService.data.success) {
            connectUserBy()
          }
        }
      } catch (err) {
        if (err.code === 4001) {
          console.log("Please connect to MetaMask.");
        } else {
          console.error(err);
        }
      } finally {
        loading.value = false; // Always set loading to false after the try block
      }
    };

    function connectWalletConnect() {
      // const modal = new WalletConnectModal({
      //   projectId: '839ac2eca1426e25e5626730b775930f'
      // })
      // await modal.openModal({ uri: 'http://localhost:8080/ '})
 
      
    }


    return {
      address,
      loading,
      connectUserBy,
      connectMetaMaskWalletHandler,
      connectWalletConnect
    };
  },
};
</script>

<style lang="scss">
.custom-dialog {
  padding: 40px 32px;
  position: relative;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
