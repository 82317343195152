import { createApp } from "vue";
import App from "./App.vue";
import {router} from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './assets/style/_variables.scss'
import './assets/style/fonts.scss'
import './assets/style/main.scss'
import './assets/style/main.scss'
import './assets/style/dark-mood.scss'
import './assets/style/light-mood.scss'
import PoolLine from './components/Base/PoolLine'


const app = createApp(App)

app.config.globalProperties.$projectId = '1';

app.component('pool-line', PoolLine)

app.use(router).use(store).use(vuetify).mount("#app");
