<template>
  <div class="footer">
    <div class="max-width text-center">
      <router-link to="/" class="d-flex align-center justify-center">
        <img
          class="footer__logo"
          :src="
            require(`@/assets/images/global/logo-${
              isDark ? 'dark' : 'light'
            }.png`)
          "
          alt="logo"
        />
      </router-link>

      <div
        class="footer__content text-center"
        :class="
          $vuetify.display.smAndDown
            ? ''
            : ' d-flex align-center  my-10 flex-wrap'
        "
      >
        <router-link
          :to="link.route"
          v-for="(link, i) in links"
          :key="'links' + i"
          class=" mb-3 d-block"
        >
          <span class="font-20 font-500 text-black2">{{ link.name }}</span>
        </router-link>
      </div>

      <div
        class="footer__social d-flex align-center  "
        :class="$vuetify.display.smAndDown ? 'justify-center my-6': 'my-10 justify-space-between'"
      >
        <a
          class="mx-5"
          :href="social.link"
          v-for="(social, i) in socials"
          :key="'socials ' + i"
        >
          <img
            :width="$vuetify.display.smAndDown  ? 20: 28"
            :src="isDark ? social.darkIcon : social.lightIcon"
            :alt="social.alt"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "FooterApp",
  data: () => ({
    links: [
      {
        name: "Referral System ",
        route: "",
      },
      {
        name: "Whitepaper",
        route: "",
      },
      {
        name: "About Us",
        route: "",
      },
      {
        name: "Privacy Policy",
        route: "",
      },
      {
        name: "Terms & Condition",
        route: "",
      },
      {
        name: "FAQ",
        route: "",
      },
    ],
    socials: [
      {
        lightIcon: require("@/assets/images/social/youtube-light.svg"),
        darkIcon: require("@/assets/images/social/youtube-dark.svg"),
        alt: "",
        link: "",
      },
      {
        lightIcon: require("@/assets/images/social/x-light.svg"),
        darkIcon: require("@/assets/images/social/x-dark.svg"),
        alt: "",
        link: "",
      },
      {
        lightIcon: require("@/assets/images/social/twitter-light.svg"),
        darkIcon: require("@/assets/images/social/twitter-dark.svg"),
        alt: "",
        link: "",
      },
    ],
  }),
  computed: {
    ...mapGetters(["isDark"]),
  },
};
</script>

<style lang="scss">
.footer {
  padding: 40px 0px;
  max-width: 1600px;
  margin: auto;
  width: 100%;

  &__content {
    max-width: 1000px;
    margin: 20px auto 20px auto;
    justify-content: space-between;
  }
  &__social {
    max-width: 300px;
    margin: 20px auto 20px auto;
  }

  @media (max-width: 875px) {
    &__content {
      justify-content: start;
    }

    &__logo {
      width: 200px;
    }

    .footer__social{
      img{
        width: 18px;
      }
    }
  }
}
</style>
