import { createStore } from "vuex";

export default createStore({
  state: {
    isDark: false,
    isUserConnected: false,
    processingKYC: true,
    connectWithKYC: false,
    publicAddress: '',
    hashedPublicAddress: ''
  },
  getters: {
    isDark(state) {
      return state.isDark
    },
    isUserConnected(state) {
      return state.isUserConnected
    },
    connectWithKYC(state) {
      return state.connectWithKYC
    },
    processingKYC(state) {
      return state.processingKYC
    },
    publicAddress(state) {
      return state.publicAddress
    },
    hashedPublicAddress(state) {
      return state.hashedPublicAddress
    },
  },
  mutations: {
    SET_STYLE_MOOD(state) {
      state.isDark = !state.isDark
    },
    SET_USER_CONNECT(state, payload) {
      state.isUserConnected = payload
    },
    SET_PAY_KYC(state, payload) {
      state.connectWithKYC = payload
      state.processingKYC = true
    },
    SET_COMPLETE_KYC(state) {
      state.processingKYC = false
    },
    SET_PUBLIC_ADDRESS(state, payload) {
      if(payload){
        state.publicAddress = payload
      }
    },
    SET_PUBLIC_ADDRESS_HASHED(state, payload) {
      if(payload){

        let strToken1 = payload.slice(0,5)
        let strToken2 = payload.slice(-5)
        state.hashedPublicAddress = strToken1 + '...' + strToken2
      }
    },


  },
  actions: {
    changeStyleMood({ commit }) {
      commit('SET_STYLE_MOOD')
    },
    connectUserHandler({ commit }, payload) {
      commit('SET_USER_CONNECT', payload)
    },
    payKYCHandler({ commit }, payload) {
      commit('SET_PAY_KYC', payload)
    },
    completeKYCHandler({ commit }, payload) {
      commit('SET_COMPLETE_KYC', payload)
    },
    setPublicAddress({ commit }, payload) {
      commit('SET_PUBLIC_ADDRESS', payload)
    },

    setPublicAddressHashed({ commit }, payload) {
      commit('SET_PUBLIC_ADDRESS_HASHED', payload)
    },

  },
  modules: {},
});
