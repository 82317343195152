<template>
  <div class="custom-dialog radius-10 text-center">
    <img
      @click="$emit('close')"
      class="custom-dialog__close cursor-pointer"
      :src="require('@/assets/images/icons/close-icon.svg')"
    />
    <!-- 
    <div class="text-center" v-if="!connectWithKYC">
      <span class="d-block font-40 text-primary font-600">Pay your KYC</span>
      <div
        class="radius-30 px-5 py-3 bg-primary cursor-pointer relative d-inline-block mx-auto mt-10"
        @click="submitHandler"
      >
        <span class="text-white font-20 font-500">Pay 5 USDT</span>
      </div>
    </div>

    <div class="text-center" v-if="connectWithKYC">
      <img
        class="mb-6"
        :src="require('@/assets/images/icons/complete-check.svg')"
      />
      <span class="d-block font-40 text-primary font-600"
        >KYC Successfully Settled</span
      >
    </div> -->
    <div id="veriff-root"></div>
  </div>
</template>

<script>
import { Veriff } from "@veriff/js-sdk";
import { mapGetters, useStore } from "vuex";

export default {
  setup() {
    let store = useStore();

    return { store };
  },
  computed: {
    ...mapGetters(["connectWithKYC"]),
  },
  methods: {
    submitHandler() {
      this.store.dispatch("payKYCHandler", true);
    },
  },
  mounted() {
    const veriff = Veriff({
      host: "https://stationapi.veriff.com",
      apiKey: "1621319f-eeb9-4ab7-9d17-86ec20162e08",
      parentId: "veriff-root",
      onSession: function (err, response) {
        window.veriffSDK.createVeriffFrame({
          url: response.verification.url,
        });
      },
    });
    veriff.setParams({
      person: {
        givenName: " ",
        lastName: " ",
      },
      vendorData: " ",
    });
    veriff.mount();
  },
};
</script>

<style lang="scss">
.custom-dialog {
  padding: 40px 32px;
  position: relative;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
