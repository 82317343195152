<template>
  <div class="navbar">
    <div
      class="navbar__content max-width align-center justify-space-between w-100 my-4 flex-wrap"
    >
      <router-link to="/" class="d-flex align-center">
        <img
          width="240px"
          :src="require(`@/assets/images/global/logo-${isDark ? 'dark': 'light'}.png`)"
          alt=""
        />
      </router-link>

      <div class="d-flex align-center pr-10">
        <router-link to="/claiming-portal" class="mx-3">
          <span class="font-20 font-500 link-active" :class="isDark ? 'text-whiteText': 'text-black'">Claiming Portal</span>
        </router-link>
        
        <router-link  to="/bidding-results" class="mx-3">
          <span class="font-20 font-500 " :class="isDark ? 'text-whiteText': 'text-black'">Bidding results</span>
        </router-link>


        <div class="relative">
          <header-btns></header-btns>
          <img
            class="theme-icon cursor-pointer"
            :src="require(`@/assets/images/icons/${isDark ? 'moon': 'sun'}.svg`)"
            @click="changeStyleHandler"
          />
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import { useTheme } from "vuetify";
import HeaderBtns from './components/HeaderBtns.vue';
import detectEthereumProvider from "@metamask/detect-provider";

export default {
  components: { HeaderBtns },
  name: "NavbarApp",
  setup() {
    let store = useStore();
    const theme = useTheme();

    return { store, theme };
  },
  data:() => ({
  }),
  computed: {
    ...mapGetters(["isDark"]),
  },
  methods: {
    changeStyleHandler() {
      this.store.dispatch("changeStyleMood");
      if (this.isDark) this.theme.global.name.value = "darkTheme";
      if (!this.isDark) this.theme.global.name.value = "lightTheme";
    },
    async getMetamaskWallet() {
      let res = await detectEthereumProvider({ silent: true });
      console.log('res', res)
      if (res) {
        console.log("The fucking wallet connected address is ", )
        this.store.dispatch('setPublicAddress', res.selectedAddress);
        this.address = res.selectedAddress;
      }
    },
  },
  async mounted(){
    // setTimeout(async () => {
      
    //   await this.getMetamaskWallet()
    // }, 500);
  }
};
</script>
z

<style lang="scss">
.navbar {
  &__content {
    position: relative;
    display: flex;
  }

  .theme-icon {
    position: absolute;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);

  }

  .link-active{

    filter: drop-shadow(0px 3px 9px #0924a9c7);
  }

  @media (max-width: 960px) {
    display: block;
  }

}
</style>
