<template>
  <div class=" custom-dialog radius-10 text-center">
    <img
      @click="$emit('close')"
      class="custom-dialog__close cursor-pointer"
      :src="require('@/assets/images/icons/close-icon.svg')"
    />

    <div class="text-center">
      <img
        class="mb-6"
        :src="require('@/assets/images/icons/complete-check.svg')"
      />
      <span class="d-block font-40 text-primary font-600">KYC Comleted!</span>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>

<style lang="scss">
.custom-dialog {
  padding: 40px 32px;
  position: relative;

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
